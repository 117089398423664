@import '../../../scss/variable.scss';
@import '../../../scss/mixin.scss';

.navBanner-item {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  transition: all 0.5s ease;
  padding: 6px 10px;
  height: 35px;
  cursor: pointer;
  border-radius: 2px;
  color: lighten($color-text-primary, 10%);
  white-space: nowrap;
  @include respond(mobile-medium) {
    width: 100%;
    padding: 0;
  }

  p {
    @include titleFont(600, 12px);

    @include respond(mobile-medium) {
      font-size: 16px;
    }
  }

  &--selected {
    transition: all 0.5s ease;
    background-color: $strong-color;
    color: white;
    @include respond(mobile-medium) {
      background-color: $strong-soft-color;
    }
  }

  div {
    display: flex;
    align-items: center;
  }
}
