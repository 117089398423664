@import '../../../scss/mixin.scss';
@import '../../../scss/variable.scss';

.nav-banner {
  width: 100%;
  transition: all 1s ease-in-out;
  border-top: 2px solid transparent;

  @include respond(mobile-medium) {
    opacity: 0;
    height: calc(100vh - 157px);
    padding: 4rem 0;
  }

  &--open {
    @include respond(mobile-medium) {
      border-top: 1px solid $strong-soft-color;
      opacity: 1;
    }
  }
  &__nav {
    @include respond(mobile-medium) {
      height: 100%;
    }
  }

  &__list {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 35px;

    @include respond(mobile-medium) {
      flex-direction: column;
      height: 100%;
    }
  }
}
