@import "../../scss/mixin.scss";
@import "../../scss/variable.scss";

.search-results {
  position: absolute;
  right: 4px;
  top: 43px;
  background-color: $strong-color;
  width: 300px;
  z-index: 9000;
  border-radius: 5px;
  border: 2px solid #cacaca;
  @include fade-in(0.3s);

  @include respond(mobile) {
    display: none;
  }

  &__no-item {
    color: $color-light-gray;
    @include titleFont(500, 12px);
    text-align: center;
    padding: 0.5rem;
  }

  &__see-more {
    padding: 0.5rem;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &-text {
      @include titleFont(500, 12px);
      text-align: center;
      color: $strong-color;
    }

    &-icon {
      margin-left: 5px;
      fill: $strong-color;
    }
  }
}