@import "../../scss/mixin.scss";
@import "../../scss/variable.scss";

.scrollTop {
  position: absolute;
  bottom: 1rem;
  background-color: $strong-color;
  padding: 0.5rem;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  cursor: pointer;
  right: 50%;
  transform: translateX(50%);
}