@import '../../scss/mixin.scss';

.logo {
  display: flex;
  align-items: center;
  width: fit-content;

  @include respond(mobile-medium) {
    margin: 0 auto;
    padding: 1rem;
    width: fit-content;
  }

  &__img {
    height: 75px;
    width: 75px;
    margin-right: -14px;
  }

  &__title-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 20px;
  }

  &__title,
  &__subtitle {
    font-family: 'Vollkorn', serif;
  }

  &__title {
    font-size: 28px;
    line-height: 15px;
  }

  &__subtitle {
    font-weight: 400;
    font-size: 23px;
    padding-left: 3px;
  }
}
