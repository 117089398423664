@import "../../scss/mixin.scss";

.wrapper {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  position: relative;

  @include respond(tablet) {
    max-width: 900px;
  }
}