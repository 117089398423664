@import '../../scss/variable.scss';
@import '../../scss/mixin.scss';

.search {
  width: 100px;
  display: flex;
  justify-content: flex-end;
  transition: all 0.5s ease;
  position: relative;
  height: 100%;

  @include respond(mobile-medium) {
    width: 45px;
    margin-left: 2rem;
    overflow: hidden;
  }

  &--open {
    width: 200px;
    transition: all 0.5s ease;
  }

  &-form {
    overflow: hidden;
    width: 100%;
    transition: all 0.5s ease;
    height: 100%;
    z-index: 99999;

    &--open {
      transition: all 0.5s ease;
      max-width: 200px;
      padding-left: 2rem;
    }

    @include respond(mobile) {
      margin-right: 0px;
    }

    &__input {
      width: 100%;
      height: 24px;
      color: black;
      background-color: transparent;
      border: none;
      padding-right: 25px;
      transition: all 1s ease;
      transform: scale(0.8);
      z-index: 9999999999;
      height: 100%;
      font-size: 14px;
      opacity: 0;

      &--open {
        background-color: $strong-soft-color;
        opacity: 1;
        padding-left: 1rem;
        transform: scale(1);
      }
    }

    &__input::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: black;
      opacity: 0.9;
      /* Firefox */
    }

    &__input:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: black;
    }

    &__input::-ms-input-placeholder {
      /* Microsoft Edge */
      color: black;
    }

    &__input:focus {
      outline: none;
    }

    &__icon {
      position: absolute;
      right: 0px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      color: white;
      font-size: 28px;

      @include respond(mobile) {
        right: 10px;
      }
    }
  }
}
