@import '../../scss/variable.scss';
@import '../../scss/mixin.scss';

.banner {
  border-bottom: 2px solid $strong-soft-color;
  padding-bottom: 5px;
  width: 100%;
  .wrapper {
    @include respond(mobile-medium) {
      height: 100%;
    }
  }

  @include respond(mobile-medium) {
    position: fixed;
    left: 0;
    transform: translateX(-100%);
    transition: all 0.5s ease-in-out;
    padding-bottom: 0;
    height: calc(100vh - 50px);
    opacity: 0;
    overflow: hidden;
    width: 100%;
    background-color: $strong-color;
    z-index: 999999;
  }

  &--open {
    transition: all 0.5s ease-in-out;

    @include respond(mobile-medium) {
      opacity: 1;
      transform: translateX(0%);
    }
  }
}
