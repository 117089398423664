@import './scss/variable.scss';
@import './scss/mixin.scss';

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  color: $color-text-primary;
}

.app {
  min-height: 100vh;
}
.hidden {
  opacity: 0;
  position: absolute;
  z-index: -50000;
}
