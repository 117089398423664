@import "../../scss/variable.scss";
@import "../../scss/mixin.scss";

.langs {
  width: 100px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @include respond(mobile-medium) {
    justify-content: flex-start;
    width: fit-content;
    margin-left: 20px;
  }

  &:hover>.header__lang {
    transition: all 0.5s ease-in-out;
    border-bottom: 1px solid white;
  }

  &__lang {
    cursor: pointer;
    padding-bottom: -2px;
    border-bottom: 1px solid transparent;
    transition: all 0.5s ease-in-out;

    @include textFont(600, 14px);

    &--open {
      border-bottom: 1px solid white;
    }

    &-icon {
      cursor: pointer;
      font-size: 28px;
      position: relative;
    }
  }
}