@import '../scss/variable.scss';
@import '../scss/mixin.scss';
.routes-loader {
  position: fixed;
  height: 100vh;
  width: 100vw;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: $strong-color;
  &__text {
    color: white;
    @include titleFont(500, 14px);
  }
}
