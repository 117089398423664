@mixin fade-in($sec) {
  animation: fade-in $sec ease-in-out forwards;
}

@mixin titleFont($weight, $px) {
  font-family: 'Poppins', sans-serif;
  font-weight: $weight;
  font-size: $px;
}

@mixin textFont($weight, $px) {
  font-family: 'Open Sans', sans-serif;
  font-weight: $weight;
  font-size: $px;
}

@keyframes fade-in {
  0% {
    transform: scale(0.95);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

$smallMobile: 375px;
$mobile: 520px;
$mobileMedium: 940px;
$tablet: 960px;
$desktop: 1280px;

@mixin respond($media) {
  @if $media==small-mobile {
    @media (max-width: $smallMobile) {
      @content;
    }
  } @else if $media==mobile {
    @media (max-width: $mobile) {
      @content;
    }
  } @else if $media==mobile-medium {
    @media (max-width: $mobileMedium) {
      @content;
    }
  } @else if $media==tablet {
    @media (max-width: $tablet) {
      @content;
    }
  } @else if $media==desktop {
    @media (max-width: $desktop) {
      @content;
    }
  }
}
