@import "../../scss/mixin.scss";
@import "../../scss/variable.scss";

.social {
  display: flex;
  align-items: center;

  @include respond(mobile-medium) {
    display: none;
  }

  &__text {
    color: white;
    @include textFont(600, 10px);
  }

  &__icon {
    margin-left: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  &__icons {
    display: flex;
  }
}
