@import "../../scss/mixin.scss";
@import "../../scss/variable.scss";

.search-results-item {
  border-bottom: 1px solid rgba($color-light-gray, 0.3);
  line-height: 12px;
  display: flex;
  padding: 0.5rem 0.5rem 0.5rem 0;
  cursor: pointer;

  &__icon {
    width: 25px;
    height: 25px;
  }

  p {
    color: $color-light-gray;
    @include titleFont(500, 12px);
  }


  &:last-child {
    border-bottom: none;
  }
}