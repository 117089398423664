@import "../../scss/mixin.scss";
@import "../../scss/variable.scss";

.lang-dropdown {
  position: absolute;
  min-width: 100px;
  width: fit-content;
  right: -171%;
  top: 116%;
  opacity: 1;
  border: 2px solid $strong-color;
  border-radius: 3px;
  z-index: 5000;
  background-color: $strong-soft-color;

  @include respond(mobile) {
    min-width: 100px
  }

  @include fade-in(0.4s);

  &::before {
    content: "";
    width: 40px;
    height: 40px;
    position: absolute;
    top: -10px;
    right: 20px;
    transform: rotate(45deg);
    background-color: $color-light-gray;
    border: 2px solid $strong-color;

  }

  &-inner {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    padding: 5px 0 24px 0;
    align-items: center;
    background-color: $color-light-gray;

  }

  &-item {
    padding: 18px 0 0 0;
    cursor: pointer;
    width: fit-content;
    border-bottom: 1px solid transparent;
    transition: all 0.4s ease-in-out;
    line-height: 12px;
    color: $strong-color;
    @include textFont(500, 12px);


    @include respond(mobile) {
      font-size: 11px;
    }

    &:hover {
      border-bottom: 1px solid $strong-color;
      color: $strong-color;
    }
  }
}