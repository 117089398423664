@import "../../scss/variable.scss";
@import "../../scss/mixin.scss";

.footer {
  width: 100%;
  padding: 1.5rem 0;
  border-top: 2px solid rgba($strong-soft-color, 40%);

  .wrapper {
    display: flex;
    max-width: 970px;

    @include respond(mobile-medium) {
      flex-direction: column;
      align-items: center;
    }
  }

  &__title {
    color: $color-text-primary;
    margin-bottom: 1rem;
    @include titleFont(600, 12px)
  }

  &__nav {
    width: 100%;
    display: flex;
    justify-content: space-around;

    @include respond(mobile-medium) {
      padding: 30px 10px 0 10px;
    }

    @include respond(mobile) {
      flex-direction: column;
      align-items: center;
    }
  }

  &__list-item {
    cursor: pointer;
    border-bottom: 1px solid transparent;
    transition: all 0.4s ease-in-out;
    color: lighten($color-text-primary, $amount: 1);
    width: fit-content;
    line-height: 10px;
    margin-bottom: 4px;
    @include textFont(400, 12px);

    @include respond(mobile) {
      width: unset;
      text-align: center;
    }

    &:hover {
      color: $strong-color;
      border-bottom: 1px solid $strong-color;
    }
  }

  &__follow-icons {
    @include respond(mobile) {
      display: flex;
      justify-content: center;
    }
  }

  &__follow-icon {
    color: $strong-color;
    cursor: pointer;

    &--twitter {
      margin-bottom: 1px;

    }
  }

  &__about {
    a {
      position: relative;
      top: -4px;

      &:hover {
        color: $strong-color;
        border-bottom: 1px solid $strong-color;
      }
    }

    @include respond(mobile-medium) {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 1;

    }

    @include respond(mobile) {
      margin-top: 1rem;

    }
  }

  &__categories {

    @include respond(mobile-medium) {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 1;
    }


  }

  &__follow {
    @include respond(mobile-medium) {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 1;
    }

  }
}