@import '../../scss/mixin.scss';

.fader {
  transform-origin: left;
  opacity: 0;
  display: flex;
  align-items: center;
  svg {
    opacity: 0;
    align-self: flex-start;
  }

  &--active {
    @include fade-in(0.2s);
    svg {
      opacity: 1;
    }
  }
}
