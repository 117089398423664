@import '../../scss/variable.scss';
@import '../../scss/mixin.scss';

.header {
  background-color: $strong-color;
  color: white;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 35px;

  @include respond(mobile-medium) {
    height: 50px;
  }

  &__hamb {
    margin-left: 10px;
  }

  .wrapper {
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}
